import { OLD_APP_DOMAIN, PURCHASE_FLOW_DOMAIN } from './envs';

export enum RouteName {
	AUTH = '/auth',
	AUTH_ADDITIONAL_INFO = '/auth-additional-info',
	DASHBOARD = '/dashboard',
	PREMIUM = '/premium',
	PROFILE = '/profile',
	PROFILE_COMPLETE = '/profile/complete',
	PROFILE_EDIT = '/profile/edit',
	PREMIUM_SUBSCRIBE = '/premium/subscribe',
	PREMIUM_UNSUBSCRIBE = '/premium/unsubscribe',
	CREDIT_ACCOUNT_WITHDRAWAL = '/credit-account-withdrawal',
	CREDIT_ACCOUNT_WITHDRAWAL_FORM = '/credit-account-withdrawal/form',
	CREDIT_ACCOUNT_WITHDRAWAL_SUCCESS = '/credit-account-withdrawal/success',
	CREDIT_ACCOUNT_WITHDRAWAL_REJECT = '/credit-account-withdrawal/reject',
	CREDIT_LINE = '/credit-line',
	INVOICES = '/invoices',
	AGREEMENTS = '/agreements',
	PAYMENT_AGREEMENT = '/payment/agreement',
	PAYMENT_AGREEMENT_SUCCESS = '/payment/agreement/success',
	PAYMENT_AGREEMENT_REJECT = '/payment/agreement/reject',
	PAYMENT_AGREEMENT_PENDING = '/payment/agreement/pending',
	GRACE_PERIOD = '/grace-period',
	GRACE_PERIOD_REJECT = '/grace-period/reject',
	GRACE_PERIOD_SUCCESS = '/grace-period/success',
	PAYMENT_INVOICE = '/payment/invoice',
	PAYMENT_INVOICE_SUCCESS = '/payment/invoice/success',
	PAYMENT_INVOICE_REJECT = '/payment/invoice/reject',
}

export const OLD_APP_ROUTE_NAME = {
	pay: `${OLD_APP_DOMAIN}/pay`,
	payApplication: `${OLD_APP_DOMAIN}/pay/application/:applicationReferenceKey`,
	payPremium: `${OLD_APP_DOMAIN}/pay/invoice?opt-out-onboard-to-premium-account-overdue=1`,
	payCreditAccount: `${OLD_APP_DOMAIN}/pay/credit-account/:creditAccountId`,
	invoices: `${OLD_APP_DOMAIN}/invoices`,
	profile: `${OLD_APP_DOMAIN}/profile`,
	contracts: `${OLD_APP_DOMAIN}/contracts`,
	applicationContract: `${OLD_APP_DOMAIN}/contracts/:applicationReferenceKey`,
	creditAccountContract: `${OLD_APP_DOMAIN}#getCredit`,
	applicationReduceMonthlyPayment: `${OLD_APP_DOMAIN}/reduce-monthly-payment/:applicationReferenceKey`,
	creditAccountLimitIncrease: `${OLD_APP_DOMAIN}/ca-limit-increase`,
	creditAccountModification: `${OLD_APP_DOMAIN}/ca-modification`,
} as const;

export const PURCHASE_FLOW_ROUTE_NAME = {
	creditLine: `${PURCHASE_FLOW_DOMAIN}/credit-line`,
	creditLineWithdrawal: `${PURCHASE_FLOW_DOMAIN}/credit-line-withdrawal?hash=$hash`,
} as const;
